import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import EmailVerification from '../components/EmailVerification';
import Register from '../components/Register';
import ChangePassword from '../components/ChangePassword';

function Accounts(props) {
    useEffect(() => {
        props.setFooterCheck(false);
        props.setNavCheck(false);
        props.setNavTransparentEffect(false);
    },)

    return (
        <div className="login d-flex w-100 justify-content-center align-items-center" style={{minHeight: '100vh'}}>
            <Route path="/accounts/register" component={Register} />
            <Route path="/accounts/verification/:token" component={EmailVerification} />
            <Route path="/accounts/changepassword/:token" component={ChangePassword} />
        </div>
    )
}

export default Accounts;
