import React, { useContext, useState } from 'react';
import logo from '../images/logo.svg';
import { LangContext } from '../appContext/LangContext';
import 'react-toastify/dist/ReactToastify.css';

export default function SuccessRegister(props) {
    const lang = useContext(LangContext);

    //const onRegister = {password, firstName, lastName, email, phone01, phone02, city, address, nameCompany, phoneCompany, cityCompany, addressCompany }

    console.log("lang", lang.rtl)

    return (
        <div>
            <div className="modal-header">
                <div className="header text-center d-block">
                    <img className="m-auto" src={logo} height="42" width="48" alt=""/>
                    <h4 className="modal-title mb-2" id="staticBackdropLabel">تم إنشاء حسابك بنجاح</h4>
                </div>
            </div>

            <div className="modal-body">
                <div className="text-center d-block" style={{maxWidth: "600px"}}>
                    {
                        lang.rtl ?
                            <p>
                                سوف تصلك رسالة التحقق من البريد الالكتروني الى البريد التالي
                                <b> {props.email} </b>
                                بعد التحقق من بريدك الالكتروني سيتم التواصل معك عبر رقم الهاتف لتفعيل حساب شركتك<br/><br/>
                                <i>نحن فريق <mark style={{background: "#ddd"}}>Qafilaty</mark> نشكرك على تسجيلك معنا</i>
                            </p>
                        :   <p>
                                You will receive a verification email to the following email
                                <b> example@gmail.com </b>
                                After verifying your email, you will be contacted via the phone number to activate your company's account <br/><br/>
                                <i>we are a team <mark style={{background: "#ddd"}}>Qafilaty</mark> Thank you for registering with us</i>
                            </p>
                    }

                </div>
            </div>
        </div>
    );
}
