import React, { useContext, useState } from 'react';
import logo from '../images/logo.svg';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LangContext } from '../appContext/LangContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {gql, useMutation} from "@apollo/client";
import SuccessRegister from "../pages/SuccessRegister";
import algerian_provinces from "../utils/api/algeria_provinces.json";

const schema = yup.object({
    password:   yup.string().required(),
    firstName:  yup.string().required(),
    lastName:   yup.string().required(),
    email:      yup.string().required(),
    phone01:    yup.string().required(),
    city:       yup.string().required(),
    address:    yup.string().required(),
    nameCompany:        yup.string().required(),
    phoneCompany:       yup.string().required(),
    cityCompany:        yup.string().required(),
    addressCompany:     yup.string().required(),
});

const CREATE_ADMIN = gql`
    mutation createUser($content: contentAdmin) {
        createAdminCompany(content: $content) {
            id
            name
            phone01
            email
            createdAt
            activation
        }
    }
`;

function Register() {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.accounts});
    const [registration, setRegistration] = useState(false)
    const [emailAdmin, setEmailAdmin] = useState("")

    const [createAdmin, {data, loading, error}] = useMutation(CREATE_ADMIN, {
        onCompleted: ({createAdminCompany}) => {
            if(createAdminCompany !== null) {
                toast.dismiss()
                setEmailAdmin(createAdminCompany.email)
                setRegistration(true)
            }
        },
        errorPolicy: 'all',
        onError: (error) => {
            console.log(error.message);
        }
    });

    const onRegister = ({password, firstName, lastName, email, phone01, phone02, city, address, nameCompany, phoneCompany, cityCompany, addressCompany }, setSubmitting) => {
        //console.log({password, firstName, lastName, email, phone01, phone02, city, address, nameCompany, phoneCompany, cityCompany, addressCompany })
        setSubmitting(true)
        createAdmin({
            variables: {
                "content":  {
                    "password":         password,
                    "person":  {
                        "first_name":   firstName,
                        "last_name":    lastName,
                        "email":        email,
                        "phone01":      phone01,
                        "phone02":      phone02,
                        "city":         city,
                        "address":      address,
                    },
                    "company": {
                        "nameCompany":      nameCompany,
                        "phoneCompany":     phoneCompany,
                        "cityCompany":      cityCompany,
                        "addressCompany":   addressCompany
                    }
                }
            }
        })
        setSubmitting(false)
    }

    const toastId = React.useRef(null);

    const LoadingNotify = ()=>{
        toastId.current = toast(textContent.tosats.wait, {
            autoClose: false,
            hideProgressBar: true,
            type: toast.TYPE.ERROR,
            icon: <div className="Toastify__toast-icon"><div className="Toastify__spinner"></div></div>,
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            closeButton:false,
            pauseOnHover:false,
            closeOnClick:false,
            draggable:false,
            toastId: "toastRegister"
        })
    }

    const ErrorNotify = (message) => {
        toast.update(toastId.current, {
            render: message,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            icon: true,
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER,
            closeButton:true,
            toastId: "toastRegister"
        })
    }

    if (loading) {
        LoadingNotify()
    }

    console.log("lang", lang.rtl)
    if (error) {
        const code = error.graphQLErrors[0].extensions.code;
        const msg = error.graphQLErrors[0].message;
        let message = "";
        const rtl = lang.rtl

        if (code === "ALREADY_HAS_ACCOUNT") {
            message = rtl ? "حساب المدير هذا موجود بالفعل لا يمكنك اعادة التسجيل به" : msg
        } else if (code === "EMAIL_ALREADY_EXISTS") {
            message = rtl ? "البريد الالكتروني الخاص بالمدير موجود بالفعل اتصل بفريق الدعم اذا حدث معك مشكل بتسجيل الدخول" : msg
        } else if (code === "PHONE_ALREADY_EXISTS") {
            message = rtl ? "رقم الهاتف الخاص بالمدير موجود بالفعل اتصل بفريق الدعم اذا حدث معك مشكل بتسجيل الدخول" : msg
        } else if (code === "COMPANY_ALREADY_EXISTS") {
            message = rtl ? "حساب هذه الشركة موجود بالفعل" : msg
        } else if (code === "EMAIL_COMPANY_ALREADY_EXISTS") {
            message = rtl ? "البريد الالكتروني الخاص بالشركة موجود بالفعل اتصل بفريق الدعم اذا حدث معك مشكل بتسجيل الدخول" : msg
        } else if (code === "PHONE_COMPANY_ALREADY_EXISTS") {
            message = rtl ? "رقم الهاتف الخاص بالشركة موجود بالفعل اتصل بفريق الدعم اذا حدث معك مشكل بتسجيل الدخول" : msg
        } else if (code === "NAME_COMPANY_ALREADY_EXISTS") {
            message = rtl ? "الاسم الخاص بالشركة موجود بالفعل اتصل بفريق الدعم اذا حدث معك مشكل بتسجيل الدخول" : msg
        } else if (code === "ADMIN_NOT_CREATED") {
            message = rtl ? "حدث مشكل بتسجيل حسابك قم بتحديث الصفحة او اتصل بفريق الدعم للاستفسار عن حل المشكلة" : msg
        } else if (code === "FIELDS_EMPTY") {
            message = rtl ? "تركت بعض الحقول فارغة" : msg
        }

        ErrorNotify(message)
    }

    return (
        registration === false ?
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone01: '',
                    phone02: '',
                    address: '',
                    city: '',
                    password: '',
                    nameCompany: '',
                    phoneCompany: '',
                    cityCompany: '',
                    addressCompany: ''
                }}
                //validate={schema}
                validationSchema={schema}
                onSubmit={(values, {setSubmitting}) => onRegister(values, setSubmitting)}>

                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                    <form className="modal-content register my-5 needs-validation" onSubmit={handleSubmit}
                          noValidate={true}>
                        <div>
                            <div className="modal-header">
                                <div className="header text-center d-block">
                                    <img className="m-auto" src={logo} height="42" width="48" alt=""/>
                                    <h4 className="modal-title mb-2" id="staticBackdropLabel">{textContent.register}</h4>
                                    <p>{textContent.registerPage.subtitle}</p>
                                </div>
                            </div>

                            <div className="modal-body">
                                <div className="mb-2 has-validation" style={{width: '45%'}}>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label">{textContent.nameCompany}</label>
                                    <input type="text" name="nameCompany" id="nameCompanyInput"
                                           className={`form-control ${touched.nameCompany && errors.nameCompany && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.nameCompany}
                                    />
                                    {touched.nameCompany && errors.nameCompany && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '45%'}}>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label">{textContent.phoneCompany}</label>
                                    <input type="text" name="phoneCompany" id="phoneCompanyInput"
                                           className={`form-control ${touched.phoneCompany && errors.phoneCompany && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.phoneCompany}
                                    />
                                    {touched.phoneCompany && errors.phoneCompany && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-4" style={{width: '45%'}}>
                                    <label htmlFor="exampleFormControlInput1" className="form-label">{textContent.cityCompany}</label>
                                    <select className={`form-select ${touched.cityCompany && errors.cityCompany && ("is-invalid")}`}
                                            name="cityCompany" aria-label="Default select example"
                                            onChange={handleChange} onBlur={handleBlur} value={values.cityCompany}
                                    >
                                        <option value={0} >اختر الولاية</option>
                                        {
                                            [...JSON.parse(JSON.stringify(algerian_provinces))].map((wilaya, index) => (

                                                <option value={wilaya.wilaya_code} key={index} >{wilaya.wilaya_code} - {wilaya.wilaya_name}</option>

                                            ))
                                        }
                                    </select>
                                    {touched.cityCompany && errors.cityCompany && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-4" style={{width: '45%'}}>
                                    <label htmlFor="exampleFormControlInput1"
                                           className="form-label">{textContent.addressCompany}</label>
                                    <input type="text" name="addressCompany" id="addressCompanyInput"
                                           className={`form-control ${touched.addressCompany && errors.addressCompany && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.addressCompany}
                                    />
                                    {touched.addressCompany && errors.addressCompany && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>


                                <div className="mb-2" style={{width: '45%'}}>
                                    <label htmlFor="firstNameInput">{textContent.fName}</label>
                                    <input type="text" name="firstName" id="firstNameInput"
                                           className={`form-control ${touched.firstName && errors.firstName && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.firstName}
                                    />
                                    {touched.firstName && errors.firstName && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '45%'}}>
                                    <label htmlFor="lastNameInput">{textContent.lName}</label>
                                    <input type="text" name="lastName" id="lastNameInput"
                                           className={`form-control ${touched.lastName && errors.lastName && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.lastName}
                                    />
                                    {touched.lastName && errors.lastName && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '95%'}}>
                                    <label htmlFor="LoginEmailInput">{textContent.email}</label>
                                    <input type="email" name="email" id="LoginEmailInput"
                                           className={`form-control ${touched.email && errors.email && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.email}
                                           autoComplete={"email"}
                                    />
                                    {touched.email && errors.email && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '95%'}}>
                                    <label htmlFor="LoginPasswordInput">{textContent.password}</label>
                                    <input type="password" name="password" id="LoginPasswordInput"
                                           className={`form-control ${touched.password && errors.password && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.password}
                                           autoComplete={"current-password"}
                                    />
                                    {touched.password && errors.password && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '45%'}}>
                                    <label htmlFor="phone01Input">{textContent.phoneNum}</label>
                                    <input type="text" name="phone01" id="phone01Input"
                                           className={`form-control ${touched.phone01 && errors.phone01 && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.phone01}
                                    />
                                    {touched.phone01 && errors.phone01 && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-2" style={{width: '45%'}}>
                                    <label htmlFor="phone02Input">{textContent.phoneNum2}</label>
                                    <input type="text" name="phone02" id="phone02Input" className="form-control"
                                           onChange={handleChange} onBlur={handleBlur} value={values.phone02}
                                    />
                                </div>

                                <div className="mb-3" style={{width: '45%'}}>
                                    <label htmlFor="contryInput">{textContent.city}</label>
                                    <select className={`form-select ${touched.city && errors.city && ("is-invalid")}`}
                                            name="city" aria-label="Default select example"
                                            onChange={handleChange} onBlur={handleBlur} value={values.city}
                                    >
                                        <option value={0} >اختر الولاية</option>
                                    {
                                        [...JSON.parse(JSON.stringify(algerian_provinces))].map((wilaya, index) => (

                                            <option value={wilaya.wilaya_code} key={index} >{wilaya.wilaya_code} - {wilaya.wilaya_name}</option>

                                        ))
                                    }
                                    </select>
                                    {touched.city && errors.city && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="mb-3" style={{width: '45%'}}>
                                    <label htmlFor="cityInput">{textContent.address}</label>
                                    <input type="text" name="address" id="cityInput"
                                           className={`form-control ${touched.address && errors.address && ("is-invalid")}`}
                                           onChange={handleChange} onBlur={handleBlur} value={values.address}
                                    />
                                    {touched.address && errors.address && (
                                        <small className="invalid-feedback">{textContent.invalidFeedback}</small>)}
                                </div>

                                <div className="modal-inner-footer d-flex justify-content-end form-floating">
                                    <button type="submit" disabled={isSubmitting} className="btn prm-btn">{textContent.register}</button>
                                </div>

                            </div>
                        </div>
                        <ToastContainer icon={false} rtl={lang.rtl}/>
                    </form>
                )}
            </Formik>
        : <SuccessRegister email={emailAdmin} />
    );
}

export default Register