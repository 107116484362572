import React, {useContext,useState}from 'react';
import { LangContext } from '../appContext/LangContext';
import pic from '../images/Qafilaty-12.png'

const ltrTextContent = {
    title:'High Quality',
    secTitle:'Dashboard',
    p:`Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit.`,
}
const rtlTextContent = {
    title:'جودة عالية',
    secTitle:'لوحة التحكم',
    p:`صمم وخصص المواقع سريعة الاستجابة للجوال أولاً باستخدام بوتستراب و رياكت، أشهر مجموعة أدوات مفتوحة المصدر للواجهة الأمامية في العالم.`,
}


function Screens() {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.screens});
    const [fadeDir,] = useState(()=>{return lang.rtl?"fade-left":"fade-right" })
    return (
        <div className={lang.rtl? "Screens rtl-dir": "Screens"} >
            <div className="row p-0">
                <div className="col-lg-5 col-sm-12">
                    <div className={lang.rtl? "text-container pe-3 ps-1":"text-container"}>
                        <h2 data-aos={fadeDir} data-aos-duration="1500">{textContent.title}</h2>
                        <p data-aos={fadeDir} data-aos-duration="2000">{textContent.p}</p>
                        <div data-aos-duration="2200" data-aos={lang.rtl?"fade-left":"fade-right"} className={lang.rtl? "svg-container me-auto":"svg-container ms-auto"}>
                            <svg width="32" height="32" fill="currentColor"  viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-sm-12"><div className="w-100 img-container ms-5 text-start">
                    <img src={pic} alt=""  srcSet="" style={{width: "80%", margin: "50px 20px", transform: "rotateY(0deg)", boxShadow:  "0px 1px 25px 2px #888" }} /></div>
                </div>
            </div>
        </div>
    )
}

export default Screens
