import React, {useContext,useState} from 'react';
import { LangContext } from '../appContext/LangContext';

import pic from '../images/lock.svg'
import pic2 from '../images/analytics-2.svg'
import pic3 from '../images/smartphone.svg'
import pic4 from '../images/ui-1.svg'
import pic5 from '../images/customer.svg'
import pic6 from '../images/time-and-date.svg'


const Card = (props)=>{
    return( 
    <div className="card" data-aos={props.animation.dataAos} data-aos-duration={props.animation.duration}>
        <div className="img-container">
            <img src={props.image} alt="" />
        </div>
        <div className="card-body">
            <h5>{props.title}</h5>
            <p>{props.text}</p>
        </div>
    </div>
    )
}


const cardInfo =[
    {
        title:'Multiple platforms',
        image: pic3,
        text:'Our system works on various platforms, Windows, Mac and Linux, and is responsive with Android.',
        animation:{
            dataAos:'fade-right',
            duration:'1500'
        }
    },
    {
        title:'Multiple Stores, Users & Permissions ',
        image: pic4,
        text:'Our platform supports multiple users and permissions as long as supports multiple storage locations.',
        animation:{
            dataAos:'fade-down',
            duration:'1500'
        }
    },
    {
        title:'Stats & Analysis',
        image: pic2,
        text:'The platform offers various infographics that you need to planning your business.',
        animation:{
            dataAos:'fade-left',
            duration:'1500'
        }
    },
    {
        title:'Add-ons Support',
        image: pic,
        text:'The platform includes several free and paid add-ons.',
        animation:{
            dataAos:'fade-right',
            duration:'2000'
        }
    },
    {
        title:'Realtime',
        image: pic6,
        text:'Real-time tracking of shipments. Various notifications in real time.',
        animation:{
            dataAos:'fade-up',
            duration:'2000'
        }
    },
    {
        title:'Multilingual',
        image: pic5,
        text:'Our program supports Arabic, French and English.',
        animation:{
            dataAos:'fade-left',
            duration:'2000'
        }
    }
]

function Features() {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.features});
    return (
        <div className="Features" id="Features">
            <div className="container">
                <h2 className="text-center py-5 my-5" data-aos="fade-down" data-aos-duration="1500">{textContent.title}</h2>
                <div className="row">

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-right" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#ff8099"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path
                                        d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[0].title}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-down" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#62d655"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[1].title}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-left" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#64a9fc"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[2].title}</h5>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-right" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#fce23a"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                    <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[3].title}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-up" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#ff7268"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5ZM1 14V4h14v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Zm7-6.507c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[4].title}</h5>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 mx-auto">
                        <div className="card" data-aos="fade-left" data-aos-duration="1500">
                            <div className="img-container" style={{background: "#7d749e"}}>
                                <svg width="40" height="40" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
                                </svg>
                            </div>
                            <div className="card-body">
                                <h5>{textContent.cardInfo[5].title}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Features
