import React,{ useContext, useState } from 'react';
import {Link} from "react-router-dom";
import { LangContext } from '../appContext/LangContext';
import Picture from '../images/Qafilaty-13.png';

let HeroSection = () => {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.heroSection});
    const [fadeDir,] = useState(()=>{return lang.rtl?"fade-left":"fade-right" });
    return (
        <div className={lang.rtl? "hero-section rtl-dir": "hero-section"}>
                <div className="row m-auto ms-2 me-2">
                    <div className="col col-md-5 intro-text-container">
                      <div className={lang.rtl ? `intro-text rtl-dir`:'intro-text'}  data-aos={fadeDir} data-aos-duration="2000">
                        <h2 className="mb-3">{textContent.introText.h1}</h2>
                        <p className="mb-5">{textContent.introText.p}</p>
                      </div>
                      <div className="intro-btn-container">
                        <Link data-aos={fadeDir} data-aos-duration="2500" className="btn intro-btn" to="/accounts/register" > {textContent.introText.btn}</Link>
                      </div>
                    </div>
                    <div className="col col-md-7 intro-pic-container">
                      <div className="intro-pic" data-aos={lang.rtl?"fade-right":"fade-left" } data-aos-duration="2000">
                       <img src={Picture} alt="" />
                      </div>
                    </div>
                </div>
        </div>
    );
};

export default HeroSection;