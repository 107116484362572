import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import AOS from 'aos/dist/aos';
import 'aos/dist/aos.css';
import './App.css';
import './css/style.scss'
import Home from './pages/Home';
import NavigationBar from './components/NavigationBar';
import Accounts from './pages/Accounts';
import Footer from './components/Footer';
import * as enLang from './lang/en.json';
import * as arLang from './lang/ar.json';
import { HashRouter as Router, Switch, Route,Redirect} from "react-router-dom";
import React, {useState} from 'react';
import { LangContext } from './appContext/LangContext';

export const LangDir = React.createContext();

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function App() {
    AOS.init();

    const [lang] = useState(()=>{
        switch (getCookie('lang')) {
            case 'en':
                return enLang.default
            case 'ar':
                return arLang.default
            default:
                return arLang.default
        }
    })
    const [footerCheck, setFooterCheck] = useState();
    const [navCheck, setNavCheck] = useState();
    const [navTransparentEffect, setNavTransparentEffect] = useState();

    return (
        <LangContext.Provider value={lang}>
            <div className={lang.rtl? "App rtl-font":"App ltr-font"} dir={lang.rtl? 'rtl': 'ltr'}>
                <Router>
                    {navCheck? <NavigationBar transparentEffect={navTransparentEffect} /> : ''}
                    <Switch>
                        <Route exact={true} path="/">
                            <Home setFooterCheck={setFooterCheck} setNavCheck={setNavCheck} setNavTransparentEffect={setNavTransparentEffect} />
                        </Route>
                        <Redirect exact from="/accounts" to="/accounts/register" />
                        <Route path="/accounts">
                            <Accounts setFooterCheck={setFooterCheck} setNavCheck={setNavCheck} setNavTransparentEffect={setNavTransparentEffect} />
                        </Route>
                    </Switch>
                    {footerCheck? <Footer /> : ''}
                </Router>
            </div>
        </LangContext.Provider>
    );
}
export default App;