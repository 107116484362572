import React,{ useContext, useState } from 'react';
import { LangContext } from '../appContext/LangContext';
import pic from '../images/apicards.svg';

const ltrTextContent = {
    title: 'Compatible API With Other Platforms',
    p: `In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.`,
}
const rtlTextContent = {
    title: <span>متوافق مع<span> API </span>المنصات الأخرى</span>,
    p: `في النشر والتصميم الجرافيكي ، يعد نصًا نائبًا يُستخدم بشكل شائع لتوضيح الشكل المرئي لمستند أو شكل محرف دون الاعتماد على محتوى ذي معنى. يمكن استخدام كعنصر نائب قبل توفر النسخة النهائية.`,
}

function ApiSection() {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.apiSection});
    const [fadeDir,] = useState(()=>{return lang.rtl?"fade-left":"fade-right" })
    return (
        <div className="api-section">
        <div className="container">
        <div className="row">
        <div className="col-lg-6 col-sm-12  text-sm-center text-lg-left text-xl-left">
        <h2 data-aos={fadeDir} data-aos-duration="2000">{textContent.title}</h2>
        <p data-aos={fadeDir} data-aos-duration="2000">{textContent.p}</p>
        </div>
          <div className="col-lg-6 col-sm-12">
          <div className="img-container">
              <img src={pic} alt="" srcSet="" />
            </div>
          </div>
          </div>
          </div>
      </div>
    )
}

export default ApiSection
