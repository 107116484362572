import React, {useState, useRef, useContext}from 'react';
import { LangContext } from '../appContext/LangContext';
import {Link, NavLink,useLocation} from "react-router-dom";
import Logo from './Logo';

function  NavigationBar ({transparentEffect}) {
    const location = useLocation();
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.navbar});
    const navBgWhite = "navbar fixed-top navbar-expand-md navbar-dark navbar-bg-white";
    const navBgTransparent = "navbar fixed-top navbar-expand-md navbar-dark";
    const navRef = useRef();
    const navbarRef = useRef();
    const btnRef = useRef();
    const DropDownRef = useRef();
    const setLang =() =>{
        if(lang.rtl){
            document.cookie = "lang=en"
            window.location.reload()
        }else{
            document.cookie = "lang=ar"
            window.location.reload()
        }
    }
    const [NavClass,setClassName] = useState(()=>{
        return window.scrollY < 50 ? navBgTransparent : navBgWhite
    });
    function setClassNameInnitial(){
        if (window.scrollY > 50 && NavClass !==navBgWhite) {
            setClassName(navBgWhite);
        }
    }
    const [medScreen,setScreen] = useState(()=>{
        try {
            return window.innerWidth < 768 ? true : false
        } catch (error) {

        }
    });
    const myVar = setInterval(()=>{
        setClassNameInnitial();
    }, 2000);

    setTimeout(()=>{clearInterval(myVar)
    }, 5000);

    setTimeout(()=>{setClassNameInnitial()
    }, 15000);

    window.onresize = () =>{
        setScreen(()=>{return window.innerWidth < 768 ? true : false});
    }

    window.onscroll = ()=>{
        try {
            if (window.scrollY < 50) {
                if (DropDownRef.current.getAttribute('aria-expanded') === 'false') {
                    if (btnRef.current.getAttribute('aria-expanded') === 'false') {
                        if (window.scrollY < 100) {
                            setTimeout(()=>{setClassName(()=>{return navBgTransparent})}, 100);
                        }
                    }
                }
            }
            else if (window.scrollY > 50) {
                if (btnRef.current.getAttribute('aria-expanded') === 'false') {
                    if (window.scrollY < 100) {
                        setTimeout(()=>{setClassName(()=>{return navBgWhite})}, 100);
                    }
                }
            }
        } catch (e) {

        }
    };

    function NavDropSetClass(){
        try {
            if (medScreen | window.scrollY < 50) {
                if (btnRef.current.getAttribute('aria-expanded') === 'true') {
                    setClassName(()=>{return navBgWhite})
                }
                if (btnRef.current.getAttribute('aria-expanded') === 'false' & window.scrollY < 100) {
                    setTimeout(()=>{setClassName(()=>{return navBgTransparent})}, 100);
                }
            }
        } catch (error) {

        }
    }

    function gotToElement(elementId) {
        if (location.pathname ==="/" ) {
            document.getElementById(elementId).scrollIntoView()
        }else{
            location.pathname='/';
            try {
                document.getElementById(elementId).scrollIntoView()
            } catch (e) {
                console.log(e);
            }
        }
    }

    return (
        <nav ref={navRef} className={transparentEffect ?  NavClass : navBgWhite}>
            <div className="container">
                <Link className="navbar-brand  my-1 py-0" to="/">
                    <Logo className="d-inline-block align-text-top" hight="" width="" />
                </Link>
                <button
                    ref={btnRef}
                    id="navbar-toggler"
                    onClick={transparentEffect ? NavDropSetClass : ()=>{}}
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded= "false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div ref={navbarRef} className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" exact to="/">{textContent.navElements.home}</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link"  to="/">
                                <span onClick={()=>{gotToElement("Features")}}>{textContent.navElements.about}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                <span onClick={()=>{gotToElement("Footer")}}>{textContent.navElements.contact}</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center">
                        {/*<span className="lang-btn" onClick={setLang}>{lang.rtl? 'En':'Ar'}</span>*/}
                        <Link className="text-decoration-none btn nav-btn" role="button" to="/accounts/register">{textContent.login}</Link>
                    </div>
                </div>
            </div>

        </nav>);
}
export default NavigationBar;