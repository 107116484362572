import React, {useContext, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {useMutation, gql} from "@apollo/client";

import Loading from '../components/Loading';
import ErrorCompoment from '../components/ErrorCompoment';

import VerificationAskSVG from '../images/ev1.svg'
import VerificationOkSVG from '../images/ev2.svg'

import '../css/emailVerification.scss';
import {LangContext} from "../appContext/LangContext";

const VERIFICATION_EMAIL = gql`
    mutation emailVerification($token: String) {
        emailVerification(token: $token) {
            status
        }
    }
`;

const EmailVerification = (props) => {

    let { token } = useParams();

    const [verifyEmail, { data, loading, error }] = useMutation(VERIFICATION_EMAIL, {
        onCompleted: ({emailVerification}) => {
            console.log("emailVerification", emailVerification)
        },
        errorPolicy: 'all',
        onError: (error) => {
            console.error(error.message);
        }
    });

    useEffect(() => {
        verifyEmail({
            variables: { token }
        })
    }, [token]);

    console.log({ data, loading, error })

    return (
    <>
        <div className="alert-container">
            {error !== undefined ? <ErrorCompoment /> : ''}
            {loading && error == undefined && data == undefined ? <Loading /> : ''}
            {(data != undefined && loading == false ) && (data.emailVerification.status ? <VPage confirm={true} /> : <VPage confirm={false} />)}
        </div>
    </>
    );
}

function VPage({confirm}) {
    const lang = useContext(LangContext);
    return (
        <div className="EmailVerification text-center">
        {
            confirm? 
                <>
                    <div className="img-container">
                        <img src={VerificationOkSVG} alt="" />
                    </div>
                    <h1>{lang.rtl ? "تم التحقق من بريدك الالكتروني" : "Your Email is Verifiy"}</h1>
                    <p>{lang.rtl ? "سيتم الاتصال بك من طرف فريق الدعم" : "You will be contacted by the support team"}</p>
                </>
            :
                <>
                    <div className="img-container">
                        <img src={VerificationAskSVG} alt="" />
                    </div>
                    <h1>{lang.rtl ? "تم التحقق من حسابك بالفعل" : "Your Email is Verifiy"}</h1>
                    <p>{lang.rtl ? "سيتم الاتصال بك من طرف فريق الدعم" : "You will be contacted by the support team"}</p>
                </>
        }
        </div>
    )
}

export default EmailVerification
