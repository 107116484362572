import React, {useContext} from 'react'
import logo from '../images/logo.svg'
import '../css/loading.scss';
import {LangContext} from "../appContext/LangContext";

function Loading() {
    const lang = useContext(LangContext);
    return (
        <div className="Loading text-center">
            <div className="loading-logo"><img src={logo} width='84' alt="" /></div>
            <h5>{lang.rtl ? "يرجى الانتظار" : "Please Wait"} </h5>
        </div>
    )
}
export default Loading;
