import React, {useContext} from 'react';
import {useParams } from 'react-router-dom';
import logo from '../images/logo.svg';
import { Formik } from 'formik';
import {useMutation, gql} from "@apollo/client";
import {LangContext} from "../appContext/LangContext";

const CHANGE_PASSWORD = gql`
    mutation changePassword($content: contenPassword) {
        changePassword(content: $content) {
            status
        }
    }
`

const ChangePassword =(props)=> {
    const lang = useContext(LangContext);

    let { token } = useParams();

    const [changePassword, { data, loading, error }] = useMutation(CHANGE_PASSWORD, {
        onCompleted: ({changePassword}) => {
            console.log(changePassword);
        },
        errorPolicy: 'all',
        onError: (error) => {
            console.error(error.message);
        }
    });

    const onChangePassword = (values, setSubmitting) => {
        setSubmitting(true)
        changePassword({
            variables: {
                "content": {
                    "token":  token,
                    "password":  values.password,
                    "confirmPassword": values.confermPassword
                }
            }
        })
        setSubmitting(false)
    }

    return (
        <Formik
            initialValues={{ password: '', confermPassword: '' }}
            onSubmit={(values, { setSubmitting }) => onChangePassword(values, setSubmitting)}>

            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, }) => (
                <form className="modal-content" onSubmit={handleSubmit}>
                    <div className="modal-header">
                        <div className="header text-center d-block">
                            <img className="m-auto" src={logo} height="42" width="48" alt=""/>
                            <h4 className="modal-title mb-2" id="staticBackdropLabel">Change Password</h4>
                        </div>
                    </div>

                    <div className="modal-body">
                        {
                            error !== undefined ?
                                <div className="alert alert-danger" role="alert">
                                    <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                    </svg> lang.rtl ? "لم يتم تغيير كلمة المرور يوجد خطأ" : "The password has not been changed. There is an error"
                                </div>
                            :''
                        }
                        {
                            loading && error == undefined && data == undefined ?
                                <div className="alert alert-secondary row justify-content-between align-items-center" role="alert">
                                    {lang.rtl ? "رجاءا انتظر قليلا" : "Please Whait"}
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden"></span>
                                    </div>
                                </div>
                            :''
                        }


                        {
                            data !== undefined ?
                                <div className="alert alert-success row justify-content-between align-items-center" role="alert">
                                    {lang.rtl ? "تم التعديل بنجاح" : "Edited successfully"}
                                </div>
                                : ''
                        }


                        <div className="form-floating mb-0">
                            <input type="password" name="password" autoComplete={"current-password"} className="form-control  mb-2" id="LoginPasswordInput"
                                   onChange={handleChange} onBlur={handleBlur} value={values.password}  placeholder="Password" />
                            <label htmlFor="LoginPasswordInput">Password</label>
                        </div>

                        <div className="form-floating mb-0">
                            <input type="password" className="form-control  mb-2" id="LoginconfermPasswordInput"
                                   name="confermPassword" autoComplete={"current-password"}  onChange={handleChange} onBlur={handleBlur} value={values.confermPassword}  placeholder="Conferm password" />
                            <label htmlFor="LoginconfermPasswordInput">Conferm password</label>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-between">
                        <button type="submit" disabled={isSubmitting} className="btn prm-btn">Change</button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default ChangePassword