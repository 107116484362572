import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import IntroIcons from '../components/IntroIcons';
import Features from '../components/Features';
import ApiSection from '../components/ApiSection';
import Screens from '../components/Screens';

function Home(props) {
    useEffect(() => {
        props.setFooterCheck(true);
        props.setNavCheck(true);
        props.setNavTransparentEffect(true);
        mounted()
    },)
    function mounted() {
        document.documentElement.style.scrollBehavior = 'auto';
        setTimeout(() => window.scrollTo(0, 0), 5);
        setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 5);
    }
    const pricesRef = React.createRef();
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <HeroSection />
            <IntroIcons />
            <Screens />
            <Features />
            <ApiSection />
        </div>
    );
}

export default Home;