import React, {useContext,useState}from 'react';
import {LangDir} from '../App';
import { LangContext } from '../appContext/LangContext';

const ltrTextContent = {
    stability:{
        title:'Stability',
        p:'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form.'
    },
    quality:{
        title:'Quality',
        p:'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form.'
    },
    speed:{
        title:'High Speed',
        p:'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form.'
    },
}
const rtlTextContent = {
    stability:{
        title:'إستقرار تام',
        p:'لوريم إيبسوم هو نص عنصر نائب يُستخدم بشكل شائع لتوضيح الشكل المرئي.'
    },
    quality:{
        title:'جودة عالية',
        p:'لوريم إيبسوم هو نص عنصر نائب يُستخدم بشكل شائع لتوضيح الشكل المرئي.'
    },
    speed:{
        title:'سرعة كبيرة',
        p:'لوريم إيبسوم هو نص عنصر نائب يُستخدم بشكل شائع لتوضيح الشكل المرئي.'
    },
}

function IntroIcons() {
    const lang = useContext(LangContext);
    const [textContent,] = useState(()=>{return lang.introIcons});
    console.log(textContent);
  return (      
  <div className="intro-icons">
  <div className="container">
    <div className="row justify-content-around align-items-center">
      <div className="col-md-4 col-sm-10">
        <div className="img-container mx-auto mb-2" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1000">
          <span><svg  fill="currentColor" viewBox="0 0 16 16">
            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"/>
            <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
          </svg>
          </span>
          <h5>{textContent.stability.title}</h5>
          <p>{textContent.stability.p}</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-10">
        <div className="img-container mx-auto mb-2" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1250">
        <span>
          <svg fill="currentColor"  viewBox="0 0 16 16">
            <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/>
            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
          </svg>
          </span>
          <h5>{textContent.quality.title}</h5>
          <p>{textContent.quality.p}</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-10">
        <div className="img-container mx-auto mb-2" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="1500">
        <span><svg aria-hidden="true" focusable="false" viewBox="0 0 512 512">
            <path fill="currentColor" d="M256 504c137 0 248-111 248-248S393 8 256 8 8 119 8 256s111 248 248 248zm0-448c110.5 0 200 89.5 200 200s-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56zm20 328h-40c-6.6 0-12-5.4-12-12V256h-67c-10.7 0-16-12.9-8.5-20.5l99-99c4.7-4.7 12.3-4.7 17 0l99 99c7.6 7.6 2.2 20.5-8.5 20.5h-67v116c0 6.6-5.4 12-12 12z"></path>
          </svg>
          </span>
          <h5>{textContent.speed.title}</h5>
          <p>{textContent.speed.p}</p>
        </div>
      </div>
    </div>
  </div>
</div>
  );
}

export default IntroIcons;
