import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` ),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
    uri: 'https://api.qafilaty.com/graphql',
    credentials: 'include'
})

const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    credentials: 'include'
});

document.title= "Qafilaty";

ReactDOM.render(
    <ApolloProvider client={client} >
        <React.StrictMode>
                <App />
        </React.StrictMode>
    </ApolloProvider>,
    document.getElementById('root')
);

reportWebVitals();
